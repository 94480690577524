import React, { useEffect } from 'react';

import * as S from './NavigationToggleActionSc';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/pro-regular-svg-icons';
import { useLingui } from '@lingui/react';
import * as langi18n from '@lingui/core';
import { Trans } from '@lingui/macro';

const NavigationToggleAction: React.FC = () => {
  const { i18n } = useLingui();
  const location = useLocation();
  const [navClass, setNavClass] = React.useState<string>('');
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const handleClickMenu = () => {
    if (navClass === 'active') {
      setNavClass('');
      document.body.style.overflow = 'auto';

      return;
    }

    setNavClass('active');
    document.body.style.overflow = 'hidden';
  };

  const handleChangeLanguage = (lang: string) => {
    langi18n.i18n.activate(lang);

    setNavClass('');
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    setNavClass('');
    document.body.style.overflow = 'auto';
  }, [location.pathname]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  if (windowWidth <= 485) {
    return (
      <>
        <S.Container onClick={handleClickMenu}>
          <FontAwesomeIcon icon={faBars} size={window.innerWidth <= 290 ? "lg" : "xl"} />
        </S.Container>

        <S.NavigationContainer className={navClass}>
          <S.NavigationContent>
            <S.CloseButton onClick={handleClickMenu}>
              <FontAwesomeIcon icon={faClose} size={"2x"} />
            </S.CloseButton>

            <S.LangaugeContainer>
              <S.LanguageItem 
                active={(i18n.locale === 'de').toString()}
                onClick={() => handleChangeLanguage('de')}
              >
                DE
              </S.LanguageItem>

              <S.LanguageItem
                active={(i18n.locale === 'tr').toString()}
                onClick={() => handleChangeLanguage('tr')}
              >
                TR
              </S.LanguageItem>
            </S.LangaugeContainer>

            <S.NavigationItemContainer>
              <S.NavigationItem>
                <S.NavigationLink 
                  to="/"
                  state={{ id: "allgemein" }}
                  onClick={handleClickMenu}
                >
                  <Trans>Allgemein</Trans>
                </S.NavigationLink>
              </S.NavigationItem>

              <S.NavigationItem>
                <S.NavigationLink 
                  to="/"
                  state={{ id: "standort" }}
                  onClick={handleClickMenu}
                >
                  <Trans>Standort</Trans>
                </S.NavigationLink>
              </S.NavigationItem>

              <S.NavigationItem>
                <S.NavigationLink 
                  to={'/'}
                  state={{ id: "ablauf" }}
                  onClick={handleClickMenu}
                >
                  <Trans>Ablauf</Trans>
                </S.NavigationLink>
              </S.NavigationItem>
              
              <S.NavigationItem>
                <S.NavigationLink
                  to={'/'}
                  state={{ id: 'hotels' }}
                  onClick={handleClickMenu}
                >
                  <Trans>Hotels</Trans>
                </S.NavigationLink>
              </S.NavigationItem>
              
              <S.NavigationItem>
                <S.NavigationLink
                  to={'/'}
                  state={{ id: 'kontakt' }}
                  onClick={handleClickMenu}
                >
                  <Trans>Abmelden</Trans>
                </S.NavigationLink>
              </S.NavigationItem>
            </S.NavigationItemContainer>
          </S.NavigationContent>
        </S.NavigationContainer>
      </>
    );
  }

  return (
    <S.DesktopContainer>
      <S.DesktopNavigationItem>
        <S.DesktopNavigationItemLink
          to={'/'} 
          state={{ id: "allgemein" }}
        >
          <Trans>Allgemein</Trans>
        </S.DesktopNavigationItemLink>
      </S.DesktopNavigationItem>

      <S.DesktopNavigationItem>
        <S.DesktopNavigationItemLink
          to={'/'}
          state={{ id: "standort" }}
        >
          <Trans>Standort</Trans>
        </S.DesktopNavigationItemLink>
      </S.DesktopNavigationItem>

      <S.DesktopNavigationItem>
        <S.DesktopNavigationItemLink
          to={'/'}
          state={{ id: "ablauf" }}
        >
          <Trans>Ablauf</Trans>
        </S.DesktopNavigationItemLink>
      </S.DesktopNavigationItem>

      <S.DesktopNavigationItem>
        <S.DesktopNavigationItemLink
          to={'/'}
          state={{ id: "hotels" }}
        >
          <Trans>Hotels</Trans>
        </S.DesktopNavigationItemLink>
      </S.DesktopNavigationItem>

      <S.DesktopNavigationItem>
        <S.DesktopNavigationItemLink
          to={'/'}
          state={{ id: "kontakt" }}
        >
          <Trans>Abmelden</Trans>
        </S.DesktopNavigationItemLink>
      </S.DesktopNavigationItem>
    </S.DesktopContainer>
  );
};

export default NavigationToggleAction;
