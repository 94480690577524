import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

import * as S from './PageLoaderSc';

export const PageLoader: React.FC = () => (
  <S.LoaderContainer>
    <FontAwesomeIcon icon={faCircleNotch} spin size={"2xl"} color={'#000'} />
  </S.LoaderContainer>
);
