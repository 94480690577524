import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface NavigationItemProps {
    active?: string | undefined;
    small?: string;
}

export const DesktopContainer = styled.div(() => [
    css`
        display: flex;
        flex-direction: row;
        align-items: center;
    `,
]);

export const DesktopNavigationItem = styled.div(() => [
    css`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
    `,
]);

export const DesktopNavigationItemLink = styled(Link)<NavigationItemProps>(({ theme, active }) => [
    css`
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${theme.text}px;
        color: ${theme.color['neutral-700']};
        text-decoration: none;
        margin-top: 5px;
        padding-bottom: 5px;
        border-bottom: 3px solid transparent;

        &:hover {
            color: ${theme.color.dark};
            border-color: ${theme.color.dark};
        }

        @media (max-width: 485px) {
            font-size: ${theme.textM}px;
        }
    `,

    active === 'true' && css`
        color: ${theme.color.primary};
        font-weight: 500;
        border-color: ${theme.color.primary};
    `,
]);

export const Container = styled.div(() => [
    css`
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `,
]);

export const NavigationContainer = styled.div(({ theme }) => [
    css`
        width: 0px;
        height: 100vh;
        right: -400px;
        overflow: hidden;
        background-color: ${theme.color.white};
        box-shadow: 5px 5px 10px grey;
        z-index: 11;
        opacity: 0;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        position: absolute;
        top: 0;
        right: 0;

        &&.active {
            opacity: 0.97;
            width: 400px;

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    `,
]);

export const NavigationContent = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        padding: 20px;
        height: calc(100% - 40px);
    `,
]);

export const CloseButton = styled.div(({ theme }) => [
    css`
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
    `,
]);

export const NavigationItemContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin-top: 20px;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
    `,
]);

export const NavigationItem = styled.div(({ theme }) => [
    css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    `,
]);

export const NavigationItemFooter = styled.div(({ theme }) => [
    css`
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 200px;
    `,
]);

export const NavigationLink = styled(Link)<NavigationItemProps>(({ theme, active, small }) => [
    css`
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${theme.titleS}px;
        color: ${theme.color['neutral-700']};
        text-decoration: none;

        &:hover {
            color: ${theme.color.dark};
            font-weight: 500;
        }
    `,

    active === 'true' && css`
        color: ${theme.color.primary};
        font-weight: 500;
    `,

    small === 'true' && css`
        font-size: ${theme.text}px;
    `,
]);

export const LangaugeContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    `,
]);

export const LanguageItem = styled.p<NavigationItemProps>(({ theme, active }) => [
    css`
        font-size: ${theme.titleS}px;
        color: ${theme.color.dark};
        margin: 0;
        padding: 0 10px;
        margin: 0 10px;
        cursor: pointer;
        padding-bottom: 10px;
        border-bottom: 2px solid transparent;
    `,

    active === 'true' && css`
        color: ${theme.color.primary};
        border-bottom-color: ${theme.color.primary};
    `,
]);
