import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';

import * as S from './SigninSc';
import axios from 'axios';

interface FormstateProps {
  firstName: string;
  lastName: string;
}

const Signin: React.FC = () => {
  const [emailAnswer, setEmailAnswer] = useState('');
  const [formstate, setFormstate] = useState<FormstateProps>({
    firstName: '',
    lastName: '',
  });

  const emailSend = () => {
    setFormstate({
      firstName: '',
      lastName: '',
    });

    setEmailAnswer(t`Vielen Dank. Email wurde gesendet!`);
}

const handleEmailValidation = () => {
  if (formstate.firstName === '') {
    return false;
  }

  if (formstate.lastName === '') {
    return false;
  }

  return true;
};

const handleSignin = () => {
  if (handleEmailValidation()) {
    const data = {
      user_id: 'user_h0L0JLict9SkL1cawrB4o',
      service_id: 'service_vqufy8j',
      template_id: 'template_h0184y8',
      template_params: formstate
    };

    axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
      .then(function (response) {
        emailSend();
      })
      .catch(function (error) {
        setEmailAnswer(t`Email wurde nicht verschickt. Da ist leider etwas schief gelaufen...`);
      });
    }
};

  return (
    <S.Container>
      <S.MainTitle>
        <Trans>Abmelden Titel</Trans>
      </S.MainTitle>

      {
        emailAnswer !== '' && (
          <S.EmailResponse>
            <Trans>{emailAnswer}</Trans>
          </S.EmailResponse>
        )
      }

      <S.SignFormContainer>
        <S.SignInputContainer>
          <S.SignInputTitle>
            <Trans>Vorname</Trans>
          </S.SignInputTitle>

          <S.SignInput
            value={formstate.firstName}
            onChange={e => setFormstate({...formstate,firstName:e.target.value})} 
          />
        </S.SignInputContainer>

        <S.SignInputContainer>
          <S.SignInputTitle>
            <Trans>Nachname</Trans>
          </S.SignInputTitle>

          <S.SignInput
            value={formstate.lastName}
            onChange={e => setFormstate({...formstate,lastName:e.target.value})}
          />
        </S.SignInputContainer>

        <S.SignButton
          disabled={
            formstate.firstName === '' || 
            formstate.lastName === ''
          }
          onClick={handleSignin}
        >
          <S.SignButtonText>
            <Trans>Abmeldung abschicken</Trans>
          </S.SignButtonText>
        </S.SignButton>
      </S.SignFormContainer>
    </S.Container>
  );
};
  
export default Signin;