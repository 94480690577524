import React from 'react';

import { Trans } from '@lingui/macro';
import MapImg from 'assets/images/antalya-location.png';

import * as S from './LocationSc';

const Location: React.FC = () => (
    <S.Container>
      <S.BackgroundImg
        src={require('assets/images/antalya/antalya.jpg')}
        srcSet={`
          ${require('assets/images/antalya/antalya-500.jpg')} 500w, 
          ${require('assets/images/antalya/antalya-800.jpg')} 800w,
          ${require('assets/images/antalya/antalya-1024.jpg')} 1024w,
          ${require('assets/images/antalya/antalya-1280.jpg')} 1280w,
        `}
        alt={"Antalya Manzara"}
      />

      <S.InnerContainer>
        <S.LocationTextContainer>
          <S.LocationText>
            Kuğulu Park
          </S.LocationText>

          <S.LocationLink
            href={"https://www.google.com/maps/place/Ku%C4%9Fulu+Park+Manzara/@36.8838384,30.6806925,17z/data=!3m1!4b1!4m6!3m5!1s0x14c391b456e815ef:0x8a49d4c13cde1cfa!8m2!3d36.8838384!4d30.6828812!16s%2Fg%2F11bx41rtyl"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <Trans>
              Bahçelievler, 07050 Muratpaşa/Antalya, Türkei
            </Trans>
          </S.LocationLink>
        </S.LocationTextContainer>

        <S.LocationMapContainer>
          <S.MapImgContainer>
            <S.MapImg src={MapImg} alt={"Map Location"} />
          </S.MapImgContainer>
        </S.LocationMapContainer>
      </S.InnerContainer>
    </S.Container>
  );
  
  export default Location;