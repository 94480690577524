import styled, { css } from "styled-components";

export const LoaderContainer = styled.div(
    ({theme}) => [
      css`
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.color.white};
      `,
    ],
  );
  