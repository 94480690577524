import styled, { css } from 'styled-components';

export const Container = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: column;
        background-color: ${theme.color.primary};
        padding: 50px 10px;
    `,
]);

export const InnerContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 675px;
        width: calc(100% - 20px);
        margin: auto;
    `,
]);

export const MainTitle = styled.p(({ theme }) => [
    css`
        font-size: ${theme.title}px;
        color: ${theme.color.white};
        margin: 0;
        width: 100%;
        margin-bottom: 20px;
    `,
]);

export const HotelContainer = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
    `,
]);

export const HotelItem = styled.div(() => [
    css`
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 20px 0;

        @media (max-width: 485px){
            flex-direction: column;
        }
    `,
]);

export const HotelItemImg = styled.div(() => [
    css`
        width: 150px;
        height: 150px;
    `,
]);

export const HotelImg = styled.img(() => [
    css`
        width: inherit;
        height: inherit;
        object-fit: cover;
    `,
]);

export const HotelItemContent = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        @media (max-width: 485px){
            margin-left: 0px;
            margin-top: 10px;
        }
    `,
]);

export const HotelItemTitle = styled.p(({ theme }) => [
    css`
        margin: 0;
        font-size: ${theme.titleM}px;
        color: ${theme.color.white};
    `,
]);

export const HotelItemPrice = styled.p(({ theme }) => [
    css`
        margin: 0;
        font-size: ${theme.titleS}px;
        margin: 10px 0;
        color: ${theme.color.white};
    `,
]);

export const HotelItemRating = styled.p(({ theme }) => [
    css`
        margin: 0;
        font-size: ${theme.titleS}px;
        color: ${theme.color.white};
    `,
]);

export const HotelItemLocation = styled.a(({ theme }) => [
    css`
        margin: 0;
        font-size: ${theme.text}px;
        text-decoration: none;
        color: ${theme.color.white};
    `,
]);
