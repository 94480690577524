import React from 'react';

import { faStar as faStarReg } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Afflon from 'assets/images/hotels/afflon.jpeg';
import Falez from 'assets/images/hotels/falez.jpeg';
import Nashira from 'assets/images/hotels/nashira.jpeg';

import * as S from './HotelsSc';

const Hotels: React.FC = () => {
    const { i18n } = useLingui();

    return (
    <S.Container>
        <S.InnerContainer>
            <S.MainTitle>
                <Trans>Hotel Tipps</Trans>
            </S.MainTitle>

            <S.HotelContainer>
                <S.HotelItem>
                    <S.HotelItemImg>
                        <S.HotelImg src={Falez} alt={"Özkaymak Falez Hotel"} />
                    </S.HotelItemImg>

                    <S.HotelItemContent>
                        <S.HotelItemTitle>Özkaymak Falez Hotel</S.HotelItemTitle>

                        <S.HotelItemRating>
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"} />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                        </S.HotelItemRating>

                        <S.HotelItemPrice>
                            {i18n.locale === 'de' ? (
                                '100.-'
                            ) : (
                                '2190 TL'
                            )}
                        </S.HotelItemPrice>

                        <S.HotelItemLocation href='http://falez.ozkaymakhotels.com.tr' target='_blank'>
                            Bahçelievler Mah. Tarık Akıltopu Cad. No:1 Muratpaşa, 07050 Antalya, Türkei
                        </S.HotelItemLocation>
                    </S.HotelItemContent>
                </S.HotelItem>

                <S.HotelItem>
                    <S.HotelItemImg>
                        <S.HotelImg src={Afflon} alt={"Afflon Hotels Sea Hill Concept"} />
                    </S.HotelItemImg>

                    <S.HotelItemContent>
                        <S.HotelItemTitle>Afflon Hotels Sea Hill Concept</S.HotelItemTitle>

                        <S.HotelItemRating>
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"} />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                        </S.HotelItemRating>

                        <S.HotelItemPrice>
                            {i18n.locale === 'de' ? (
                                '210.-'
                            ) : (
                                '4032 TL'
                            )}
                        </S.HotelItemPrice>

                        <S.HotelItemLocation href='https://afflonhotels.com.tr/seahill/' target='_blank'>
                            <Trans>Deniz Mahallesi 131.Sokak No: 7 Deniz, 131. Sk. NO:7, 07050 Muratpaşa/Antalya</Trans>
                        </S.HotelItemLocation>
                    </S.HotelItemContent>
                </S.HotelItem>

                <S.HotelItem>
                    <S.HotelItemImg>
                        <S.HotelImg src={Nashira} alt={"Nashira City Resort Hotel"} />
                    </S.HotelItemImg>

                    <S.HotelItemContent>
                        <S.HotelItemTitle>Nashira City Resort Hotel</S.HotelItemTitle>

                        <S.HotelItemRating>
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"} />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStar} color='#FFA200' size={"sm"}  />
                            <FontAwesomeIcon icon={faStarReg} color='#FFA200' size={"sm"}  />
                        </S.HotelItemRating>

                        <S.HotelItemPrice>
                            {i18n.locale === 'de' ? (
                                '155.-'
                            ) : (
                                '3396 TL'
                            )}
                        </S.HotelItemPrice>

                        <S.HotelItemLocation href='https://nashirahotels.com' target='_blank'>
                            Meltem Mah. Sakıp Sabancı Blv. No:12, 07050 Antalya, Türkei
                        </S.HotelItemLocation>
                    </S.HotelItemContent>
                </S.HotelItem>
            </S.HotelContainer>
      </S.InnerContainer>
    </S.Container>
  );
};
  
  export default Hotels;
