import styled, { css } from 'styled-components';

export const Container = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75vh;
        position: relative;
    `,
]);

export const BackgroundImg = styled.img(() => [
    css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.6;
    `,
]);

export const InnerContainer = styled.div(() => [
    css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        max-width: 900px;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        @media (max-width: 685px) {
            flex-direction: column;
        }
    `,
]);

export const LocationTextContainer = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: calc(50% - 30px);
        padding: 15px;
        text-align: start;

        @media (max-width: 685px) {
            width: calc(100% - 30px);
        }
    `,
]);

export const LocationMapContainer = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: calc(50% - 30px);
        padding: 15px;

        @media (max-width: 685px) {
            width: calc(100% - 30px);
        }
    `,
]);

export const MapImgContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        max-width: 350px;
        width: 100%;
        border: 0.5px solid ${theme.color.dark};
    `,
]);

export const MapImg = styled.img(() => [
    css`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `,
]);

export const LocationLink = styled.a(({ theme }) => [
    css`
        margin: 0;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        width: 100%;
        color: ${theme.color.dark};
        text-shadow: 1px 1px 2px ${theme.color.white}, 
            0 0 1em ${theme.color.white}, 
            0 0 0.2em ${theme.color.white};

        @media (max-width: 685px) {
            max-width: 350px;
            width: 100%;
        }

        @media (max-width: 485px) {
            font-size: 6.5vw;
        }
    `,
]);

export const LocationText = styled.p(({ theme }) => [
    css`
        margin: 0;
        font-size: 30px;
        font-weight: bold;
        width: 100%;
        color: ${theme.color.dark};
        text-shadow: 1px 1px 2px ${theme.color.white}, 
            0 0 1em ${theme.color.white}, 
            0 0 0.2em ${theme.color.white};

        @media (max-width: 685px) {
            max-width: 350px;
            width: 100%;
        }

        @media (max-width: 485px) {
            font-size: 6.5vw;
        }
    `,
]);
