import React from 'react';

import { Trans } from '@lingui/macro';
import Guelguen from 'assets/images/Guelguen.png';
import Mikail from 'assets/images/Mikail.png';

import * as S from './GeneralSc';

const General: React.FC = () => (
    <S.Container>
      <S.GeneralInformationContainer>
        <S.GeneralInformationInnerContainer>
          <S.GeneralInformationText>
            <Trans>07. Oktober 2023</Trans>
          </S.GeneralInformationText>
        </S.GeneralInformationInnerContainer>

        <S.GeneralInformationInnerContainer>
          <S.GeneralInformationLink
            href={"https://www.google.com/maps/place/Ku%C4%9Fulu+Park+Manzara/@36.8838384,30.6806925,17z/data=!3m1!4b1!4m6!3m5!1s0x14c391b456e815ef:0x8a49d4c13cde1cfa!8m2!3d36.8838384!4d30.6828812!16s%2Fg%2F11bx41rtyl"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <Trans>Bahçelievler, 07050 Muratpaşa/Antalya, Türkei</Trans>
          </S.GeneralInformationLink>
        </S.GeneralInformationInnerContainer>
      </S.GeneralInformationContainer>

      <S.PersonContainer>
        <S.PersonInner>
          <S.PersonImgContainer>
            <S.PersonImg src={Mikail} alt={"Mikails Bild"} />
          </S.PersonImgContainer>

          <S.PersonTextContainer>
            <S.PersonName>
              Mikail
            </S.PersonName>
          </S.PersonTextContainer>
        </S.PersonInner>

        <S.PersonInner>
          <S.PersonImgContainer>
            <S.PersonImg src={Guelguen} alt={"Gülgüns Bild"} />
          </S.PersonImgContainer>

          <S.PersonTextContainer>
            <S.PersonName>
              Gülgün
            </S.PersonName>
          </S.PersonTextContainer>
        </S.PersonInner>
      </S.PersonContainer>
    </S.Container>
  );
  
export default General;
