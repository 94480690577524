import React from 'react';

import * as S from './NotFoundSc';

const NotFoundPage: React.FC = () => (
  <S.Container>
    <S.InnerContainer>
      <S.SubTitle>Da ist etwas schiefgelaufen</S.SubTitle>
      <S.Title>Seite wurde nicht gefunden</S.Title>

      <S.GoBackButton to="/">Zurück zur Startseite</S.GoBackButton>
    </S.InnerContainer>
  </S.Container>
);

export default NotFoundPage;
