import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div(() => [
  css`
    display: flex;
    height: 100vh;
  `,
]);

export const InnerContainer = styled.div(() => [
  css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
]);

export const Title = styled.h1(({ theme }) => [
  css`
    color: ${theme.color.dark};
    font-size: ${theme.title}px;
    text-align: center;
    margin: 0;

    @media (max-width: 768px) {
      font-size: ${theme.titleM}px;
    }
  `,
]);

export const SubTitle = styled.h1(({ theme }) => [
  css`
    color: ${theme.color.dark};
    font-size: ${theme.titleS}px;
    text-align: center;
    margin: 0;

    @media (max-width: 768px) {
      font-size: ${theme.text}px;
    }
  `,
]);

export const GoBackButton = styled(Link)(({ theme }) => [
  css`
    margin-top: 40px;
    text-decoration: none;
    color: ${theme.color.white};
    background-color: ${theme.color.primary};
    padding: 10px 20px;
    border-radius: 5px;
  `,
]);
