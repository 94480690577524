import styled, {css} from 'styled-components';

export const Container = styled.div(() => [
    css`
      padding-horizontal: 15px;
      padding-vertical: 10px;
      display: flex;
      flex-direction: column;
    `,
  ],
);

export const Section = styled.div(() => [
  css`
    scroll-margin-top: 140px;

    @media (max-width: 485px) {
      scroll-margin-top: 20vw;
    }
  `,
]);
