import { Trans } from '@lingui/macro';
import React from 'react';

import HomeImg from 'assets/images/Home2.jpg';

import * as S from './HomeHeaderSc';

const HomeHeader: React.FC = () => (
    <S.Container>
      <S.HomeImage src={HomeImg} alt={""} width={"100%"} height={"100%"} />

      <S.TextContainer>
        <S.HomeHeaderTitle>
          <Trans>Wir heiraten!</Trans>
        </S.HomeHeaderTitle>

        <S.HomeHeaderSubTitleSmall>
          <Trans>Wir freuen uns, Sie an diesem besonderen Tag unter uns zu sehen.</Trans>
        </S.HomeHeaderSubTitleSmall>

        <S.HomeHeaderSubTitle>
          <Trans>07. Oktober 2023</Trans>
        </S.HomeHeaderSubTitle>
      </S.TextContainer>
    </S.Container>
  );
  
  export default HomeHeader;