import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface HeaderTopItemProps {
    active?: string;
}

export const MainContainer = styled.div(() => [
    css`
        position: sticky;
        top: 0;
        z-index: 4;
    `,
]);

export const HeaderTopContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: row;
        background-color: ${theme.color.white};
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        min-height: 30px;
        width: calc(100% - 40px);

        @media (max-width: 485px) {
            display: none;
        }
    `,
]);

export const HeaderTopInnerContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: row;
        background-color: ${theme.color.white};
        align-items: center;
        justify-content: flex-end;
        max-width: 1024px;
        width: 100%;
    `,
]);

export const HeaderTopText = styled.p<HeaderTopItemProps>(({ theme, active }) => [
    css`
        color: ${theme.color.dark};
        padding: 0;
        margin: 0px;
        margin-left: 20px;
        width: auto;
        font-size: ${theme.text}px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        @media (max-width: 768px) {
            font-size: ${theme.textS}px;
        }
    `,

    active === 'true' && css`
        color: ${theme.color.primary};
        border-bottom-color: ${theme.color.primary};
    `,
]);

export const LogoContainer = styled(Link)(({ theme }) => [
    css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 500px;
        width: 100%;
        text-decoration: none;
        color: ${theme.color.dark};
        text-transform: uppercase;

        @media (max-width: 485px) {
            width: auto;
        }
    `,
]);

export const LogoText = styled.p(() => [
    css`
        font-size: 40px;
        margin: 0;
        letter-spacing: 5px;

        @media (max-width: 485px) {
            font-size: 5.5vw;
        }
    `,
]);

export const MenuContainer = styled.div(() => [
    css`
        display: flex;
    `,
]);

export const HeaderContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 110px;
        background-color: ${theme.color.white};
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

        @media (max-width: 485px) {
            flex-direction: row;
            height: auto;
            padding: 15px;
        }
    `,
]);
