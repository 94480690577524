import type { PropsWithChildren } from 'react';
import React from 'react';

import { Outlet } from 'react-router';

import Header from '../header';
import Footer from '../footer';

import * as S from './PageSc';

const Page: React.FC<PropsWithChildren> = ({ children }) => {
  return (
        <S.PageContainer id="page">
          <Header />

          <S.MainContainer id="content">
            <Outlet />
          </S.MainContainer>

          <Footer />
        </S.PageContainer>
  );
};
export default Page;
