import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { intiI18n } from './services/i18n';
import router from 'services/routes/routes';
import { ThemeProvider } from 'styled-components';
import ThemeTypes from 'style/ThemeType';

function App() {

  React.useEffect(() => {
    intiI18n();
  }, []);

  return (
      <ThemeProvider theme={ThemeTypes}>
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={true}>
          <RouterProvider router={router} />
        </I18nProvider>
      </ThemeProvider>
  );
}

export default App;
