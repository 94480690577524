import styled, { css } from 'styled-components';

export const Container = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        max-width: 675px;
        width: 99%;
        min-height: 25vh;
        height: auto;
        margin: 30px auto;
        padding: 1%;

        @media (max-width: 710px) {
            padding: 15px;
            width: calc(100% - 30px);
        }
    `,
]);

export const MainTitle = styled.p(({ theme }) => [
    css`
        font-size: ${theme.title}px;
        color: ${theme.color.dark};
        margin: 0;
        width: 100%;
        margin-bottom: 20px;
    `,
]);

export const SignFormContainer = styled.div(() => []);

export const SignInputContainer = styled.div(() => [
    css`
        margin: 15px 0;
    `,
]);

export const SignInputTitle = styled.p(({ theme }) => [
    css`
        margin: 0;
        margin-bottom: 5px;
        font-size: ${theme.textM}px;
    `,
]);

export const SignInput = styled.input(({ theme }) => [
    css`
        width: calc(100% - 20px);
        max-width: 450px;
        height: 40px;
        border-radius: 3px;
        border: 0.5px solid ${theme.color.dark};
        padding: 0 10px;
    `,
]);

export const SignRadioContainer = styled.div(() => [
    css`
        width: 100%;
        max-width: 450px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    `,
]);

export const SignRadioItem = styled.div(() => [
    css`
        margin-right: 20px;
    `,
]);

export const SignInputRadio = styled.input(() => []);

export const SignButton = styled.button(({ theme }) => [
    css`
        width: 100%;
        max-width: 470px;
        height: 40px;
        border-radius: 3px;
        border: 0.5px solid ${theme.color.dark};
        background-color: ${theme.color.white};
    `,
]);

export const SignButtonText = styled.p(({ theme }) => [
    css`
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: ${theme.text}px;
        color: ${theme.color.dark};
    `,
]);

export const EmailResponse = styled.p(({ theme }) => [
    css`
        margin: 10px 0;
        width: 100%;
        font-size: ${theme.text}px;
        color: ${theme.color.dark};
    `,
]);
