import styled, { css } from "styled-components";

export const PageContainer = styled.div(() => [
  css`
    position: relative;
  `
]);

export const MainContainer = styled.main(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    margin-bottom: 200px;
    background-color: #fdfefe;
    z-index: 2;
  `,
]);


export const DateBanner = styled.div(({ theme }) => [
  css`
    position: fixed;
    top: 35px;
    left: -25px;
    z-index: 10;
    transform: rotateY(0deg) rotate(-45deg);
    background-color: ${theme.color.primary};

    @media (max-width: 485px) {
      transform: rotateY(0deg) rotate(-45deg);
      right: -25px;
      bottom: 35px;
      top: auto;
      left: auto;
    }
  `,
],
);

export const DateBannerText = styled.p(({ theme }) => [
css`
  margin: 0;
  font-size: ${theme.textS}px;
  color: ${theme.color.white};
  padding: 0 20px;
`,
]);
