import React from 'react';

import NavigationToggleAction from 'actions/navigationToggleAction';
import * as langi18n from '@lingui/core';
import { useLingui } from '@lingui/react';

import * as S from './HeaderSc';

const Header: React.FC = () => {
  const { i18n } = useLingui();

  const handleChangeLanguage = (lang: string) => {
    langi18n.i18n.activate(lang);
  };

  return (
    <S.MainContainer>
      <S.HeaderTopContainer>
        <S.HeaderTopInnerContainer>
          <S.HeaderTopText 
            active={(i18n.locale === 'de').toString()}
            onClick={() => handleChangeLanguage('de')}
          >
            DE
          </S.HeaderTopText>
          <S.HeaderTopText 
            active={(i18n.locale === 'tr').toString()}
            onClick={() => handleChangeLanguage('tr')}
          >
            TR
          </S.HeaderTopText>
        </S.HeaderTopInnerContainer>
      </S.HeaderTopContainer>

      <S.HeaderContainer>
        <S.LogoContainer to={'/'}>
          <S.LogoText>Gülgün ♡ Mikail</S.LogoText>
        </S.LogoContainer>

        <S.MenuContainer>
          <NavigationToggleAction />
        </S.MenuContainer>
      </S.HeaderContainer>
    </S.MainContainer>
  );
};
export default Header;
