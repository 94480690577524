import styled, {css} from 'styled-components';

export const Container = styled.div(({theme}) => [
    css`
        position: relative;
        background: ${theme.color.white};
        display: flex;
        flex-direction: column;
        height: calc(100vh - 140px);
        width: 100%;
        justify-content: center;
        align-items: center;

        @media (max-width: 485px) {
            height: 90vh;
        }
    `,
  ],
);

export const HomeImage = styled.img(() => [
    css`
        object-fit: cover;
        opacity: 1;
    `,
]);

export const TextContainer = styled.div(({theme}) => [
    css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    `,
  ],
); 

export const HomeHeaderTitle = styled.p(({theme}) => [
    css`
        margin: 0;
        font-size: 70px;
        font-family: 'ReadexPro';
        color: ${theme.color.white};
        width: 100%;
        text-align: center;
        font-family: 'Playfair';

        @media (max-width: 485px) {
            font-size: 14vw;
            padding: 0 5px;
            width: calc(100% - 10px);
        }
    `,
  ],
);

export const HomeHeaderSubTitle = styled.p(({theme}) => [
    css`
        margin: 0;
        font-size: ${theme.title}px;
        font-family: 'ReadexPro';
        color: ${theme.color.white};
        width: 100%;
        text-align: center;
        font-family: 'Playfair';

        @media (max-width: 485px) {
            font-size: 7vw;
            padding: 0 5px;
            width: calc(100% - 10px);
        }
    `,
  ],
);

export const HomeHeaderSubTitleSmall = styled.p(({theme}) => [
    css`
        margin: 0;
        font-size: ${theme.titleS}px;
        font-family: 'ReadexPro';
        color: ${theme.color.white};
        width: 100%;
        text-align: center;
        font-family: 'Playfair';

        @media (max-width: 485px) {
            font-size: 5vw;
            padding: 0 10px;
            width: calc(100% - 10px);
        }
    `,
  ],
);
