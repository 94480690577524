import React from 'react';

import * as S from './FooterSc';
import { Trans } from '@lingui/macro';

const Footer: React.FC = () => {
  return (
    <S.FooterContainer>
      <S.FooterText>Gülgün ♡ Mikail</S.FooterText>
      <S.FooterSubText>
        <Trans>07. Oktober 2023</Trans>
      </S.FooterSubText>
    </S.FooterContainer>
  );
};
export default Footer;
