import Page from "components/global/page";
import PageWrapper from "components/global/pageWrapper";
import Home from "components/pages/home";
import NotFoundPage from "components/pages/notFound";
import { createBrowserRouter } from "react-router-dom";

const basePath = '/';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: basePath,
        element: <PageWrapper component={<Home />} />,
      },
    ],
  },
]);

export default router;
