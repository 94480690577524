import styled, { css } from 'styled-components';

export const Container = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 675px;
        width: 99%;
        min-height: 25vh;
        height: auto;
        margin: 30px auto;
        padding: 1%;

        @media (max-width: 710px) {
            padding: 15px;
            width: calc(100% - 30px);
        }
    `,
]);

export const MainTitle = styled.p(({ theme }) => [
    css`
        font-size: ${theme.title}px;
        color: ${theme.color.dark};
        margin: 0;
        width: 100%;
        margin-bottom: 20px;
    `,
]);

export const BackgroundImg = styled.img(() => [
    css`
        width: 100%;
        height: 100%;
        object-fit: contain;
    `,
]);

export const ProcedureItem = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 750px;
        width: 100%;
        margin: 15px auto;
    `,
]);

export const ProcedureTitle = styled.p(({ theme }) => [
    css`
        margin: 0;
        font-weight: bold;
    `,
]);

export const ProcedureTime = styled.p(({ theme }) => [
    css`
        margin: 0;
    `,
]);

export const ProcedureText = styled.p(({ theme }) => [
    css`
        margin: 0;
    `,
]);
