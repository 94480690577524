import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import PageContentErrorFallback from './PageContentErrorFallback';
import { PageLoader } from '../pageLoader';

interface PageWrapperProps {
  component: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ component }) => {
  return (
    <ErrorBoundary
      FallbackComponent={PageContentErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <React.Suspense fallback={<PageLoader />}>
        {component}
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default PageWrapper;
