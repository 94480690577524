import styled, { css } from 'styled-components';

export const FooterContainer = styled.footer(({ theme }) => [
    css`
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: -1;
        bottom: 0px;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        padding: 0 40px;
        height: 200px;
        background-color: ${theme.color.dark};

        @media (max-width: 370px) {
            padding: 0 10%;
        }
    `,
]);

export const FooterText = styled.p(({ theme }) => [
    css`
        margin: 0;
        font-size: ${theme.title}px;
        color: ${theme.color.white};

        @media (max-width: 370px) {
            font-size: 8vw;
        }
    `,
]);

export const FooterSubText = styled.p(({ theme }) => [
    css`
        margin: 0;
        font-size: ${theme.titleS}px;
        color: ${theme.color.white};

        @media (max-width: 370px) {
            font-size: 6vw;
        }
    `,
]);
