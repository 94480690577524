/**
 * Do not edit this file. its generically produced form js modules
 */

import { Locale } from "@lingui/core";

 interface LanguageConfig {
  locales: Locale[];
  defaultLocale: Locale;
  localeDetection: boolean;
}

export const languageConfig: LanguageConfig = {
  locales: ['de', 'tr'],
  defaultLocale: 'tr',
  localeDetection: false
};
