import React from 'react';

import General from '../../screens/general';
import HomeHeader from '../../screens/homeHeader';
import Location from '../../screens/location';
import Procedure from '../../screens/procedure';
import Signin from '../../screens/signin';

import * as S from './HomeSc';
import { useLocation } from 'react-router-dom';
import Hotels from 'components/screens/hotels/Hotels';

const Home: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.state && location.state.id) {
        const element = document.getElementById(location.state.id);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
}, [location, location.state]);

  return(
    <S.Container>
      <HomeHeader />

      <S.Section id={"allgemein"} />
      <General />

      <S.Section id={"standort"} />
      <Location />

      <S.Section id={"ablauf"} />
      <Procedure />

      <S.Section id={"hotels"} />
      <Hotels />

      <S.Section id={"kontakt"} />
      <Signin />
    </S.Container>
  );
};
  
  export default Home;