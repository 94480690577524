import { i18n } from '@lingui/core';

import { messages as messagesDe } from '../i18n/locales/de/messages';
import { messages as messagesTr } from '../i18n/locales/tr/messages';

export const intiI18n = () => {
  i18n.load('tr', messagesTr);
  i18n.load('de', messagesDe);

  i18n.loadLocaleData('tr', { plurals: undefined });
  i18n.loadLocaleData('de', { plurals: undefined });

  i18n.activate('tr');
};
