import React from 'react';

import { Trans } from '@lingui/macro';

import * as S from './ProcedureSc';

const Procedure: React.FC = () => (
    <S.Container>
      <S.MainTitle>
        <Trans>Ablauf</Trans>
      </S.MainTitle>

      <S.ProcedureItem>
        <S.ProcedureTitle><Trans>Gäste empfangen</Trans></S.ProcedureTitle>
        <S.ProcedureTime><Trans>17:45 - 18:00 Uhr</Trans></S.ProcedureTime>
      </S.ProcedureItem>

      <S.ProcedureItem>
        <S.ProcedureTitle><Trans>Erster Tanz</Trans></S.ProcedureTitle>
        <S.ProcedureTime><Trans>18:15 Uhr</Trans></S.ProcedureTime>
      </S.ProcedureItem>

      <S.ProcedureItem>
        <S.ProcedureTitle><Trans>Essen</Trans></S.ProcedureTitle>
        <S.ProcedureTime><Trans>19:00 Uhr</Trans></S.ProcedureTime>
      </S.ProcedureItem>

      <S.ProcedureItem>
        <S.ProcedureTitle><Trans>Hochzeitsfeier</Trans></S.ProcedureTitle>
        <S.ProcedureTime><Trans>19:45 - 00:00 Uhr</Trans></S.ProcedureTime>
      </S.ProcedureItem>

      <S.ProcedureItem>
        <S.ProcedureTitle><Trans>After-Party</Trans></S.ProcedureTitle>
        <S.ProcedureTime><Trans>00:00 Uhr</Trans></S.ProcedureTime>
      </S.ProcedureItem>
    </S.Container>
  );
  
  export default Procedure;