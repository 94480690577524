import styled, { css } from 'styled-components';

export const Container = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 20px);
        padding: 50px 10px;
    `,
]);

export const GeneralInformationContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        flex-direction: row;
        max-width: 750px;

        @media (max-width: 668px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 485px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    `,
]);

export const GeneralInformationInnerContainer = styled.div(({ theme }) => [
    css`
        display: flex;
        width: calc(50% - 40px);
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-right: 1px solid ${theme.color.dark};

        :nth-last-child(odd) {
            border: none;
        }

        @media (max-width: 668px) {
            width: calc(100% - 40px);
            border-right: none;
            border-bottom: 1px solid ${theme.color.dark};

            :nth-last-child(odd) {
                border: none;
            }
        }
    `,
]);

export const GeneralInformationText = styled.p(({ theme }) => [
    css`
        margin: 0;
        color: ${theme.color['neutral-800']};
        font-size: ${theme.title}px;
        text-align: center;
        width: 100%;

        @media (max-width: 668px) {
            font-size: ${theme.titleS}px;
        }
    `,
]);

export const GeneralInformationLink = styled.a(({ theme }) => [
    css`
        margin: 0;
        color: ${theme.color['neutral-800']};
        text-decoration: none;
        font-size: ${theme.title}px;
        text-align: center;
        width: 100%;

        @media (max-width: 668px) {
            font-size: ${theme.titleS}px;
        }
    `,
]);

export const PersonContainer = styled.div(() => [
    css`
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 700px;
        justify-content: space-between;
        margin-top: 50px;
    `,
]);

export const PersonInner = styled.div(() => [
    css`
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1%;
    `,
]);

export const PersonImgContainer = styled.div(() => [
    css`
        max-width: 350px;
    `,
]);

export const PersonImg = styled.img(() => [
    css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    `,
]);

export const PersonTextContainer = styled.div(() => [
    css`
        display: flex;
        flex-direction: column;
    `,
]);

export const PersonName = styled.p(() => [
    css`
        margin: 0;
        margin-top: 5%;
        font-size: 30px;
        width: 100%;
        text-align: center;

        @media (max-width: 960px) {
            font-size: 25px;
        }

        @media (max-width: 720px) {
            font-size: 3.5vw;
        }
    `,
]);

export const PersonText = styled.p(() => [
    css`
        margin: 0;
        margin-top: 3%;
        font-size: 15px;
        width: 100%;

        @media (max-width: 960px) {
            font-size: 15px;
        }

        @media (max-width: 720px) {
            font-size: 2vw;
        }
    `,
]);
