import {AppColors} from './AppColors';

export type CustomTheme = typeof ThemeObj;

const AppColor: AppColors = {
  primary: '#135900',
  'primary-dark': '#E67F00',
  'primary-light': '#FFA433',
  white: '#fdfefe',
  'white-primary': '#fffdfc',
  dark: '#191919',
  'dark-primary': '#191816',
  secondary: '#0072FF',
  'secondary-dark': '#005BCC',
  'secondary-light': '#338EFF',
  'neutral-0': '#FFFFFF',
  'neutral-100': '#F2F2F2',
  'neutral-200': '#EBEBEB',
  'neutral-300': '#D7D7D7',
  'neutral-400': '#A9A9A9',
  'neutral-500': '#919191',
  'neutral-600': '#797979',
  'neutral-700': '#5A5A5A',
  'neutral-800': '#464646',
  'neutral-900': '#000000',
  'success-dark': '#007936',
  success: '#009032',
  'success-light': '#E5F4EC',
  'success-bg': '#E5F4EC',
  error: '#D02B20',
  'error-dark': '#A62219',
  'error-light': '#D9554C',
  'error-bg': '#FFF5F4',
  'warning-dark': '#D9B53A',
  warning: '#EFBD11',
  'warning-light': '#F7DF8D',
  'warning-bg': '#FDF8E7',
  'info-dark': '#1C225A',
  info: '#4755E3',
  'info-light': '#9099EE',
  'info-bg': '#ECEEFC',
  mark: '#FFF79F',
};

export const ThemeObj = {
  color: AppColor,
  title: 30,
  titleM: 25,
  titleS: 20,
  text: 16,
  textM: 14,
  textS: 12,
  textXs: 10,
  spacing: 15,
};

const ThemeTypes = ThemeObj;

export default ThemeTypes;
