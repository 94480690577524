import React from 'react';

import type { FallbackProps } from 'react-error-boundary';
// import { useNavigate } from 'react-router';

// import * as S from './PageWrapperSc';

type PageContentErrorFallbackProps = FallbackProps & {
  children?: React.ReactNode;
};

const PageContentErrorFallback: React.FC<PageContentErrorFallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  // const navigate = useNavigate();

  return (
    <>
      <h1>Etwas ist schief gelaufen</h1>
    </>
  );
};

export default PageContentErrorFallback;
